import React from "react";
import { useNavigate } from "react-router-dom";
import { RaiState } from "../config/RaiContext";

const Function = () => {
  const navigate = useNavigate();

  const { user, logOut } = RaiState();

  return (
    <div className="h-screen flex flex-col">
      {/* Logo and Title Section */}
      <div className="flex items-center max-w-full  gap-2 mt-[30px] ml-[60px]">
        <img className="w-40" src="logo.svg" alt="icon" />
        {user && (
          <div className="ml-auto mr-10">
            <div className="flex justify-end">
              <button
                className=" mt-[20px] mr-10 text-red-600 font-medium "
                onClick={logOut}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="2em"
                  width="2em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
                  <path d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"></path>
                </svg>
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Title */}
      <div className=" w-full h-[63px] md:mt-[120px] px-4 md:px-0">
        <h1 className="text-[24px]  md:text-[42px] font-semibold font-poppin text-center">
          <span style={{ color: "#010ED0" }}>Select</span> the function you want
          us to perform
        </h1>
      </div>

      {/*Cards Section */}

      <div className="w-full h-[450px]  flex justify-center">
        <div className=" h-[400px] w-[790px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 mt-8">
          <div
            className="border border-gray-300 h-[180px] w-[245px] rounded-2xl flex flex-col items-center justify-center shadow-sm hover:shadow-md  cursor-pointer"
            onClick={() => navigate("/analyse")}
          >
            <img
              className=" w-[81px] h-[90px] mb-4"
              src="AnalyzeData.png"
              alt="analyzedata"
            />
            <h3 className="text-xl font-medium text-gray-700">Analyse Data</h3>
          </div>
          <div className="border border-gray-300 h-[180px] w-[245px] rounded-2xl flex flex-col items-center justify-center shadow-sm hover:shadow-md cursor-pointer">
            <img
              className=" w-[81px] h-[90px] mb-4"
              src="Summarize.png"
              alt="analyzedata"
            />
            <h3 className="text-xl font-medium text-gray-700">Summarize</h3>
          </div>

          <div className="border border-gray-300 h-[180px] w-[245px] rounded-2xl flex flex-col items-center justify-center shadow-sm hover:shadow-md cursor-pointer">
            <img
              className=" w-[81px] h-[80px] mb-4"
              src="Document.png"
              alt="document"
            />
            <div>
              <h3 className="text-xl font-medium text-gray-700">
                Chat with the
              </h3>
              <h3 className="text-xl font-medium text-gray-700 pl-3">
                document
              </h3>
            </div>
          </div>

          <div
            className="border border-gray-300 h-[180px] w-[245px] rounded-2xl flex flex-col items-center justify-center shadow-sm hover:shadow-md cursor-pointer"
            onClick={() => navigate("/invoice")}
          >
            <img
              className=" w-[81px] h-[90px] mb-4"
              src="Invoice.png"
              alt="invoice"
            />
            <h3 className="text-xl font-medium text-gray-700">Invoice Parse</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Function;
