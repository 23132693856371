import React, { useState } from "react";
import { RaiState } from "../config/RaiContext";

const InvoiceParseThird = ({ answer, push, loading, files }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const { logOut } = RaiState();

  // Export to JSON
  const exportJson = (data) => {
    if (!data) return;

    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "invoice.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <aside className="col-span-3  bg-white border-l border-gray-200 drop-shadow-md">
      <div className="flex justify-end">
        <button
          className=" mt-[20px] mr-10 text-red-600 font-medium "
          onClick={logOut}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 24 24"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
            <path d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"></path>
          </svg>
        </button>
      </div>
      <div className=" flex w-[250px] justify-between mt-[30px] ml-10 mb-2 font-medium text-base">
        <h1>{files[0]?.name}</h1>
        <button onClick={() => setIsOpen((prev) => !prev)}>
          <img className="w-6 h-6" src="3bar.png" alt="icon" />
        </button>
      </div>
      {/* dropdown  */}
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 z-50">
          <ul className="py-1 text-sm text-gray-700">
            {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
              Undo all changes
            </li> */}
            <li
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => exportJson(push)}
            >
              Export as JSON
            </li>
            {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
              Save File
            </li> */}
            {/* <li className="px-4 py-2 hover:bg-gray-100 text-red-500 cursor-pointer">
              Delete
            </li> */}
          </ul>
        </div>
      )}
      {/* rendering two sections */}
      <div className=" flex items-center border-b mt-4">
        <div className="flex-1 cursor-pointer text-center pb-2 border-b-2 font-semibold">
          Final Results
        </div>
      </div>
      <div>
        {loading ? (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <div key={index}>
                <div className="flex items-center gap-2 w-full p-2">
                  <div className="w-4 h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  <div className="w-full h-5 bg-gray-300 rounded animate-pulse"></div>
                </div>

                <div className="flex items-center gap-2 w-full p-2">
                  <div className="w-4 h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  <div className="w-full h-5 bg-gray-300 rounded animate-pulse"></div>
                </div>

                <div className="flex items-center gap-2 w-full p-2">
                  <div className="w-4 h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  <div className="w-full h-5 bg-gray-300 rounded animate-pulse"></div>
                </div>
              </div>
            ))}
          </>
        ) : (
          answer && (
            <div className="space-y-4">
              <div className=" flex justify-between ml-3 font-medium mt-2">
                <h4>Fields</h4>
                <img
                  onClick={() => setIsExpanded((prev) => !prev)}
                  className=" h-6 w-6"
                  src="Arrowup.png"
                  alt="up icon"
                />
              </div>
              {isExpanded && (
                <>
                  <div className="h-[550px] w-full overflow-y-auto custom-scrollbar">
                    <ul className="p-3 space-y-2">
                      {push?.map((item, index) => {
                        const key = Object.keys(item)[0];
                        const value = item[key];
                        return (
                          <li
                            key={index}
                            className="flex justify-between items-start py-2"
                          >
                            {/* Key Section */}
                            <div className="flex gap-2 items-center">
                              <img
                                className="w-2 h-2"
                                src="half.png"
                                alt="half icon"
                                style={{ marginTop: "0.1rem" }} // Fine-tune the icon alignment
                              />
                              <span
                                style={{ color: "#5F6567" }}
                                className="text-gray-700 font-medium text-xs"
                              >
                                {key}
                              </span>
                            </div>
                            {/* Value Section */}
                            <span
                              className="font-medium text-xs text-right break-words"
                              style={{
                                maxWidth: "60%", // Ensure it wraps in its allocated space
                                wordWrap: "break-word",
                              }}
                            >
                              {value || "N/A"}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              )}
            </div>
          )
        )}
      </div>
    </aside>
  );
};

export default InvoiceParseThird;
