import axios from "axios";
import React, { useState, useEffect } from "react";
import { RaiState } from "../config/RaiContext";
import { ask_question, uploadCSV } from "../config/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Analyse = () => {
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isButton, setIsButton] = useState(false);
  const { user, files, setFiles, logOut } = RaiState();

  const sessionId = user?.uid; // to identify the user and store its session inside local storage

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handlequery();
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);
    console.log("Mounted");

    // Clean up event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      console.log("Unmounted");
    };
  });

  // user feedback
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  // speaking functionality
  const handleReadAloud = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(utterance);
  };

  const handleFileClick = () => {
    document.getElementById("file-upload").click();
  };

  // Handleling api call when upload files
  const handleFileUpload = async (e) => {
    const newFiles = Array.from(e.target.files);

    const invalidCsv = newFiles.filter(
      (file) =>
        file.type !== "text/csv" && !file.name.toLowerCase().endsWith(".csv")
    );

    if (invalidCsv.length > 0) {
      toast.error(
        `Only CSV files are allowed. Invalid file(s): ${invalidCsv
          .map((file) => file.name)
          .join(", ")}`
      );
      return;
    }

    const updatedFiles = [...files, ...newFiles];
    setFiles(updatedFiles);

    const formData = new FormData();

    updatedFiles.forEach((file) => {
      formData.append("files", file);
    });

    formData.append("session_id", sessionId);

    console.log(formData);

    try {
      const response = await axios.post(uploadCSV(), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        toast.success("File(s) uploaded successfully!");
        setIsButton(true);
      } else {
        toast.error("Failed to upload files");
      }
    } catch (error) {
      toast.error("Error uploading Files", error);
    }
  };
  // removing files from an array of files
  const removeFiles = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  // handling second api when user ask questions
  const handlequery = async () => {
    const queryformData = new FormData();
    queryformData.append("session_id", sessionId);
    queryformData.append("query", message);
    try {
      const res = await axios.post(ask_question(), queryformData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const answer = res.data.answer;
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { question: message, answer: answer },
      ]);
      setMessage("");
    } catch (err) {
      console.log("Error in query", err);
    }
  };

  return (
    <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-9 h-screen bg-gray-50">
      {/* First section */}
      <aside className="col-span-2 bg-white border-r border-gray-200 p-6 flex flex-col drop-shadow-md">
        <div className="flex items-center  gap-2 ml-[40px] sm:ml-[20px] mb-5">
          <img className="w-40" src="logo.svg" alt="icon" />
        </div>

        <button
          className="bg-blue-600 text-white rounded-lg flex items-center justify-center h-[38px] w-[204px] gap-3 font-medium mt-12 sm:mt-6"
          style={{ backgroundColor: "#010ED0" }}
          onClick={() => window.location.reload()}
        >
          Start New Chat
          <img className="w-6 h-6" src="Arrow.png" alt="arrow" />
        </button>
        <button
          className="rounded-lg flex items-center justify-center h-[38px] w-[204px] gap-3 font-medium mt-12 sm:mt-6"
          style={{ backgroundColor: "#DEE0FF", color: "#010ED0" }}
          onClick={handleFileClick}
        >
          <img className="w-6 h-6" src="Upload.png" alt="arrow" />
          Upload Files
          <input
            type="file"
            id="file-upload"
            class="hidden"
            onChange={handleFileUpload}
            multiple
          />
        </button>

        {/* showing file information */}
        <div className=" flex gap-2 mr-14 mt-2">
          <img className="w-4 h-4" src="Frame.png" alt="frame" />
          <h4 className="text-sm">Please select .csv files only</h4>
        </div>
        <div className="flex flex-col mt-2 w-[210px] h-[60px]">
          {files.map((file) => (
            <div
              key={file.name}
              className="flex items-center border border-gray-200 rounded-full gap-3 h-[35px] p-2 mt-2"
              style={{ backgroundColor: "#F5F5F5" }}
            >
              <img src="csv.png" alt="csv icon" className="w-5 h-5" />
              <span className=" text-base font-medium text-gray-700">
                {file.name}
              </span>
              <button
                onClick={() => removeFiles(file.name)}
                className="text-gray-500 hover:text-gray-700"
              >
                X
              </button>
            </div>
          ))}
        </div>
      </aside>

      {/* Second section */}
      <div class="col-span-5 bg-white items-center justify-center flex flex-col">
        <div className="h-[60px] w-full flex pt-[4px] justify-center shadow-md">
          <h1 className="text-2xl font-bold text-gray-800">
            Perform Analysis on the Documents
          </h1>
        </div>

        <div className="overflow-y-auto w-full flex flex-col-reverse items-center  h-[550px] mt-2 mb-2 custom-scrollbar">
          {chatHistory
            .slice()
            .reverse()
            .map((chat, index) => (
              <div key={index} className="rounded-lg w-3/4  mt-6">
                {/* <!-- User Message Section --> */}
                <div className="flex items-start space-x-3 mb-4 p-2 w-3/2  justify-end rounded-lg ml-5">
                  <img
                    className=" w-6 h-6"
                    src="Rectangle(1).png"
                    alt="icon description"
                  />

                  <div
                    className=" flex  p-2 rounded-lg gap-2"
                    style={{ backgroundColor: "#F5F5F5" }}
                  >
                    <p className="text-gray-700 font-normal text-sm pt-1">
                      {chat.question}
                    </p>
                    <img
                      className=" w-6 h-6 ml-auto"
                      src="Rectangle(2).png"
                      alt="rectangle"
                    />
                  </div>
                </div>

                {/* <!-- Response Section --> */}
                <div className="  p-4 rounded-lg shadow  overflow-y-auto mb-2">
                  <div className="flex items-center mb-2">
                    <img className="mb-auto" src="loogo.png" alt="icon" />
                    <div className=" ml-3 font-medium">
                      <p className="text-gray-600 text-sm mb-12 font-normal whitespace-pre-wrap">
                        {chat.answer}
                      </p>
                      <div className="flex space-x-3 text-gray-500 border border-none w-[180px] h-[35px] rounded-md p-2">
                        <button
                          onClick={() => handleReadAloud(chat.answer)}
                          className="hover:text-gray-700"
                        >
                          <img src="VolumeUp.png" alt="voulme up icon" />
                        </button>
                        <button
                          onClick={() => handleCopy(chat.answer)}
                          className="hover:text-gray-700"
                        >
                          <img src="Frame(3).png" alt="voulme up icon" />
                        </button>
                        <button
                          onClick={() => alert("Thanks for your feedback")}
                          className="hover:text-gray-700"
                        >
                          <img src="Vector.png" alt="voulme up icon" />
                        </button>
                        <button
                          onClick={() => alert("Thanks for your feedback")}
                          className="hover:text-gray-700"
                        >
                          <img src="Vector(1).png" alt="voulme up icon" />
                        </button>
                        <button className="hover:text-gray-700">
                          <img src="Frame(4).png" alt="voulme up icon" />
                        </button>
                        <button className="hover:text-gray-700">
                          <img src="ArrowDown2.png" alt="voulme up icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Action Icons --> */}
              </div>
            ))}
        </div>

        <div className="flex items-center w-full max-w-lg p-2 bg-gray-100 rounded-lg shadow-md h-[50px] mx-auto ">
          <input
            type="text"
            placeholder="Ask me anything...."
            className="flex-grow px-3 py-2 text-gray-600 bg-gray-100 border-0 focus:outline-none"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />

          {isButton ? (
            <button
              className="ml-2 text-white rounded-lg flex items-center w-[50px] h-[38px] justify-center gap-4"
              style={{ backgroundColor: "#010ED0" }}
              onClick={handlequery}
            >
              <img src="Send.png" alt="send" className="w-6 h-6" />
            </button>
          ) : (
            <button
              className="ml-2 text-white rounded-lg flex items-center w-[50px] h-[38px] justify-center gap-4"
              style={{ backgroundColor: "#5F6567" }}
            >
              <img src="send(2).png" alt="send" className="w-9 h-8" />
            </button>
          )}
        </div>

        {/* // infomation about  app */}
        <div className="flex items-center w-full max-w-lg p-2 justify-between rounded-lg mt-2 h-[30px] mx-auto">
          <div className=" flex gap-2 mr-14">
            <img className="w-4 h-4" src="Frame.png" alt="frame" />
            <h4 className="text-sm">upload .csv files to begin Analysis</h4>
          </div>
        </div>
        <h4 className=" text-xs">
          R-ai can make mistakes. Re-check at your end{" "}
        </h4>
      </div>

      {/* Third section */}
      <aside className="col-span-2  bg-white p-6 border-l border-gray-200 drop-shadow-md">
        <div className="flex justify-end">
          <button
            className="  mr-10 text-red-600 font-medium "
            onClick={logOut}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="2em"
              width="2em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
              <path d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"></path>
            </svg>
          </button>
        </div>
        <h2 className="text-xl font-semibold text-gray-800 mb-4 mt-[60px]">
          Suggestive Analysis
        </h2>

        <div className="space-y-6 mt-6">
          <div>
            <div className=" flex gap-2">
              <img src="Paper.png" alt="paper icon" className=" w-6 h-6" />
              <h3 className=" font-semibold">Sales Analysis</h3>
            </div>
            <ul className="text-gray-700 mt-2 space-y-2 text-xs font-medium">
              <li>
                Are there any goods issue notes prepared prior to release of
                Sales Order?
              </li>
              <li>Are there any goods dispatched against cancelled order?</li>
              <li>
                Lorem Ipsum has been the industry’s standard dummy text ever
                since the 1500s
              </li>
            </ul>
          </div>

          <div>
            <div className=" flex gap-2">
              <img src="Paper.png" alt="paper icon" className=" w-6 h-6" />
              <h3 className=" font-semibold">Inventor Analysis</h3>
            </div>
            <ul className="text-gray-700 mt-2 space-y-2 text-xs font-medium">
              <li>
                Lorem Ipsum has been the industry’s standard dummy text ever
                since the 1500s
              </li>
              <li>
                Are there any goods issue notes prepared prior to release of
                Sales Order?
              </li>
              <li>Are there any goods dispatched against cancelled order?</li>
            </ul>
          </div>

          <div>
            <div className=" flex gap-2">
              <img src="Paper.png" alt="paper icon" className=" w-6 h-6" />
              <h3 className="font-semibold">Expenses Analysis</h3>
            </div>
            <ul className="text-gray-700 mt-2 space-y-2 text-xs font-medium">
              <li>
                Lorem Ipsum has been the industry’s standard dummy text ever
                since the 1500s
              </li>
              <li>
                Are there any goods issue notes prepared prior to release of
                Sales Order?
              </li>
              <li>Are there any goods dispatched against cancelled order?</li>
            </ul>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Analyse;
